import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import AppImplementation from '@constants/crossPlatform';

// Assets
import headerImage from '@assets/services/cross-platform/cross-platform-header.jpg';

import AIBasedAppIcon from '@assets/services/cross-platform/ai-based-mobile-app.svg';
import AIVRAppIcon from '@assets/services/cross-platform/ar-vr-apps.svg';
import ECommerceAppIcon from '@assets/services/cross-platform/e-commerce.svg';
import EnterpriseMobilityIcon from '@assets/services/cross-platform/enterprise-mobility-solutions.svg';
import HealthcareAppsIcon from '@assets/services/cross-platform/healthcare-apps.svg';
import OnDemandAppIcon from '@assets/services/cross-platform/on-demand-custom-apps.svg';
import SocialNetworkIcon from '@assets/services/cross-platform/social-network-apps.svg';
import UtilityAppsIcon from '@assets/services/cross-platform/utility-apps.svg';
import WearableAppsIcon from '@assets/services/cross-platform/wearable-apps.svg';

const ContentColumnWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  justify-content: space-between;
`;

const ColumnContent = styled.div`
  display: flex;
  padding: 20px;
  flex: 1;
  flex-flow: column wrap;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex: 0 0 100%;
    font-size: 14px;
  }
`;

const ListItem = styled.div`
  padding-left: 15px;
  position: relative;
  margin: 5px 0;
  &:before {
    content: ' ';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-flex;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: ${props => props.theme.colors.primary.base};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
  }
`;

const CrossPlatformWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const ElemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: center;
`;

const ItemWrapper = styled.div`
  width: 300px;
  padding: 20px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
  svg {
    margin-bottom: 20px;
  }
`;

const SubHeading = styled.p`
  margin-top: 20px;
  font-weight: 600;
`;

const SubContent = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const CrossPlatform = () => {
  const iconMap = {
    wearable: <WearableAppsIcon />,
    aibased: <AIBasedAppIcon />,
    healthcare: <HealthcareAppsIcon />,
    utility: <UtilityAppsIcon />,
    enterprise: <EnterpriseMobilityIcon />,
    ecommerce: <ECommerceAppIcon />,
    ondemand: <OnDemandAppIcon />,
    socialnetwork: <SocialNetworkIcon />,
    arvr: <AIVRAppIcon />,
  };
  return (
    <Layout>
      <Helmet title={'Cross Platform'} />
      <PageHero image={headerImage} />
      <Heading title="Cross-Platform App Development" noHeadingIcon={true}>
        <p>
          Need an app for Android? Ok. Need an app for iOS? Hmm. Need an app for
          Windows Phone? Wait, do they still exist? Well, sure. Need an app that
          runs across all the desirable platforms? Yaaas, we got you. Our team,
          with decades of experience of working and fabricating cross-platform
          applications, has all the required knowledge and expertise to present
          you the app of your dreams which can reach your each and every client
          no matter what device they use.
        </p>

        <p>
          The biggest challenge cross-platform app developers face is that
          whenever a mobile operating system receives a new update, the
          applications must also be updated to be compatible with the new
          system. But our easy yet complex team management consisting of experts
          of different platforms collaborate in such a way that you'll get the
          best app experience across all platforms simultaneously.
        </p>

        <p>
          How do we do what we do? Our developers develop high-quality
          cross-platform apps for native iOS, Android, and the web—all from a
          single codebase while using the latest technologies like Sencha,
          jQuery, AngularJS, Xamarin, PhoneGap, etc.
        </p>
      </Heading>
      <Container>
        <SectionHeading>Services to watch out for</SectionHeading>
        <ContentColumnWrapper>
          <ColumnContent>
            Our team will help you in choosing the right framework and
            methodology required for your project if you're a novice in this
            field. We know the best one to be used for your package and would
            like to offer the same.
          </ColumnContent>
          <ColumnContent>
            We implement a multi-platform functioning model of all time. It
            supports the complete evaluation of IT and business obligations.
          </ColumnContent>
          <ColumnContent>
            We provide mobile extension services supporting the cross-platform
            sector adequately. The services will be fulfilled in a
            cost-effective practice and within a given frame of time.
          </ColumnContent>
        </ContentColumnWrapper>
      </Container>
      <Container>
        <SectionHeading>Why us?</SectionHeading>
        <ContentColumnWrapper>
          <ColumnContent>
            <ListItem>Highly Creative AND Motivated Teams</ListItem>
            <ListItem>We Understand Business Needs</ListItem>
            <ListItem>Complete Control On Your Teams</ListItem>
            <ListItem>Secured Environment</ListItem>
          </ColumnContent>
          <ColumnContent>
            <ListItem>Cost Optimization Upto 60%</ListItem>
            <ListItem>Online Project Management</ListItem>
            <ListItem>Innovation Is Guaranteed</ListItem>
            <ListItem>Dedicated Development Teams</ListItem>
          </ColumnContent>
          <ColumnContent>
            <ListItem>Devops Enablement</ListItem>
            <ListItem>True-Blue Guarantee</ListItem>
            <ListItem>Non-Disclosure Agreement</ListItem>
            <ListItem>Integrity & Transparency</ListItem>
          </ColumnContent>
        </ContentColumnWrapper>
      </Container>
      <CrossPlatformWrapper>
        <Container>
          <SectionHeading>
            How cross-platform is implemented on different apps
          </SectionHeading>
          <ElemWrapper>
            {AppImplementation.map((item, idx) => {
              return (
                <ItemWrapper key={idx}>
                  {iconMap[item.type]}
                  <SubHeading>{item.heading}</SubHeading>
                  <SubContent>{item.content}</SubContent>
                </ItemWrapper>
              );
            })}
          </ElemWrapper>
        </Container>
      </CrossPlatformWrapper>
    </Layout>
  );
};

export default CrossPlatform;
