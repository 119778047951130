const appImplementation = [
  {
    type: 'wearable',
    heading: 'Wearable Apps',
    content:
      'Fitness and health monitoring apps Internet of Things based Wearable Apps Google Glass, Android wear & iWatch apps',
  },
  {
    type: 'aibased',
    heading: 'AI-Based Mobile Apps',
    content:
      'Online service/product comparison apps Finance report & stock market apps Personal finance management apps',
  },
  {
    type: 'healthcare',
    heading: 'Healthcare Apps',
    content:
      'Blood pressure and heart rate monitors Chronic disease management systems Master Patient Indexes, Claims Adjudication',
  },
  {
    type: 'utility',
    heading: 'Utility Apps',
    content:
      'On Demand Plumber App Development On Demand House Cleaning Services Laundry App Development',
  },
  {
    type: 'enterprise',
    heading: 'Enterprise Mobility Solutions',
    content:
      'Smart home solutions Wearable gadget apps Voice based IoT solutions',
  },
  {
    type: 'ecommerce',
    heading: 'E-Commerce Apps',
    content:
      'Location-based m-commerce apps, delivery apps High-quality, photo-realistic renders, and portals In-store navigation & real-time product tracking apps',
  },
  {
    type: 'ondemand',
    heading: 'On-Demand & Custom Apps',
    content:
      'GPS and Map integration with geo-fencing Digital POD through secured cloud storages Electronic verification system & mobile scanner',
  },
  {
    type: 'socialnetwork',
    heading: 'Social Network Apps',
    content:
      'Event management and booking apps Music and video playing apps Personalized news and blog apps',
  },
  {
    type: 'arvr',
    heading: 'AR & VR Apps',
    content:
      'Complex data visualization apps through VR GEO based AR development for mobile apps High-quality, photo-realistic renders, and apps',
  },
];

export default appImplementation;
